import React, { useEffect } from 'react'

import Particles from './components/particles'
import Shape from './assets/shape.svg'
import Shape2 from './assets/blob-3.svg'
import Logo from './assets/upmedia-white.png'
import TeamSvg from './assets/undraw_team_spirit_re_yl1v.svg'
import umamiPng from './assets/umami.png'
import './App.css'

import { CgWebsite } from "react-icons/cg";
import { MdOutlineReviews } from "react-icons/md";
import { IoShareSocialSharp } from "react-icons/io5";
import { RiAdvertisementFill } from "react-icons/ri";

import { GiFrance } from "react-icons/gi";
import { MdBackup } from "react-icons/md";
import { HiOutlineSupport } from "react-icons/hi";
import { HiServerStack } from "react-icons/hi2";
import { MdNetworkWifi3Bar } from "react-icons/md";
import { FaShieldAlt, FaLock } from "react-icons/fa";

import AOS from 'aos';
import 'aos/dist/aos.css';

export const metadata = {
  title: 'Agence UpMedia',
  description: 'Agence UpMedia - Agence de communication digitale à Paris',
}

export default function ModalVideoPage() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <main className="relative flex flex-col bg-slate-800 overflow-hidden">
        <nav>
          <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-6 flex justify-between items-center">

            <a href="/" className="text-white text-xl flex">

              <img src={Logo} alt="Logo" className="h-12 logoTurnOver" />
              <span className='pl-3 pt-2 font-semibold'>Agence <span className='text-up-blue'>Up</span>Media</span>
            </a>
            <a href="#upmedia_cloud" className="text-white">
              UpMedia
              <span className='pl-1 cloud-span font-thin'>
                cloud
              </span>
            </a>
          </div>
        </nav>

        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-12">

          <div className="text-center">

            {/* Illustration #1 */}
            <div className="absolute top-0 left-0 rotate-180 -translate-x-3/4 -scale-x-100 blur-3xl opacity-70 pointer-events-none" aria-hidden="true">
              <img src={Shape} className="max-w-none" width={852} height={582} alt="Illustration" />
            </div>

            <div className="absolute top-0 right-0 blur-sm -translate-y-1/2 opacity-70 pointer-events-none" aria-hidden="true">
              <img src={Shape} className="max-w-none" width={852} height={582} alt="Illustration" />
            </div>

            {/* Particles animation */}
            <Particles className="absolute inset-0 pointer-events-none" />
          </div>

        </div>


        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24 pb-50">
          <div className="text-center">
            <h1 data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" className="font-extrabold text-5xl bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 font-arimo">Transformez votre présence en ligne en succès</h1>
            <p data-aos="fade-up" data-aos-delay="800" data-aos-duration="1700" data-aos-anchor-placement="top-bottom" className="text-xl text-white max-w-4xl m-auto opacity-80 pt-10">Création, développement ou renouvellement, l'agence UpMedia se charge d'assurer votre présence en ligne avec des solutions faites pour vous.</p>
          </div>

        </div>


        {/* Features */}
        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">

          <div data-aos-delay="900" data-aos-duration="1200" data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-up-blue text-white text-xl font-bold">
                <CgWebsite />
              </div>
              <div className="ml-6">
                <h2 className="text-2xl font-bold text-white">Développement de sites web sur mesure</h2>
                <p className="text-white opacity-80">Nous créons des sites web personnalisés en utilisant les dernières technologies comme React et Vue, sans utiliser de CMS prédéfinis, pour un contrôle total sur votre site.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-up-blue text-white text-xl font-bold">
                <FaLock />
              </div>
              <div className="ml-6">
                <h2 className="text-2xl font-bold text-white">Développement backend sécurisé</h2>
                <p className="text-white opacity-80">Nos experts développent des solutions backend robustes et sécurisées pour protéger vos données et celles de vos utilisateurs.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-up-blue text-white text-xl font-bold">
                <IoShareSocialSharp />
              </div>
              <div className="ml-6">
                <h2 className="text-2xl font-bold text-white">Gestion de réseaux sociaux</h2>
                <p className="text-white opacity-80">Nous gérons vos réseaux sociaux pour vous permettre de vous concentrer sur votre activité. Nous créons du contenu de qualité et engageant pour votre audience.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-up-blue text-white text-xl font-bold">
                <RiAdvertisementFill />
              </div>
              <div className="ml-6">
                <h2 className="text-2xl font-bold text-white">Développement d'applications web</h2>
                <p className="text-white opacity-80">Nous développons des applications web performantes et scalables adaptées à vos besoins spécifiques, en utilisant des technologies modernes pour garantir une expérience utilisateur optimale.</p>
              </div>
            </div>
          </div>
        </div>

        { /* Team section */}
        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div data-aos="fade-left" data-aos-duration="1000">
              <img src={TeamSvg} alt="Team" className="w-full" />
            </div>
            <div data-aos="fade-right" data-aos-duration="1200">
              <h2 className="text-4xl font-bold text-white text-center">Notre histoire</h2>
              <svg className="w-8 h-8 text-up-blue dark:text-up-blue mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
              </svg>
              <p className="text-white opacity-80 italic text-center">Notre site est encore en rénovation ! Retrouvez prochainement toutes les sections.</p>
            </div>
          </div>
        </div>

      </main>
      { /* Upmedia cloud */}
      <div className="w-full mx-auto px-4 md:px-6 py-24 min-w-screen bg-slate-800 relative" id='upmedia_cloud'>
        { /* put shape2 under the text */}
        <div className="text-center btn text-xl max-w-fit mx-auto" data-aos="fade-bottom" data-aos-duration="800">
          <h2 className="font-semibold text-white">UpMedia <span className='cloud-span font-thin'>cloud</span></h2>
        </div>
        <div className="absolute top-2 left-1/2 -translate-x-1/2 rotate-45 blur-3xl opacity-70 pointer-events-none" aria-hidden="true">
          <img src={Shape2} className="max-w-none" width={182} alt="Illustration" />
        </div>

        <div className="absolute rotate-180 -translate-y-1/2 opacity-70 pointer-events-none" aria-hidden="true">
          <img src={Shape} className="max-w-none md:w-[652px] w-[220  px]" alt="Illustration" />
        </div>

        <div className="absolute bottom-0 right-0 opacity-60 rotate-180 pointer-events-none" aria-hidden="true">
          <img src={Shape2} className="max-w-none" width={452} height={182} alt="Illustration" />
        </div>

        <div data-aos="fade-right" data-aos-duration="1000" className="text-center">
          <p className="text-white text-xl opacity-80 max-w-4xl m-auto pt-10">
            UpMedia Cloud est une solution d'hébergement professionnelle, sécurisée et performante, conçue pour répondre aux besoins variés de nos clients. Notre objectif est d'offrir des services personnalisés, garantissant qualité et performance à chaque utilisateur.
          </p>
        </div>

        <div data-aos="fade-bottom" data-aos-duration="800" className="mx-auto grid max-w-5xl items-start gap-8 py-12 sm:grid-cols-2 lg:grid-cols-3 lg:gap-12">
          <div className="grid gap-4">
            <GiFrance className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Serveurs situés en France</h3>
            <p className="text-gray-300">
              Ayant une infrastructure situé en France, nous garantissons la sécurité et la confidentialité de vos données conformément à la législation européenne.
            </p>
          </div>

          <div className="grid gap-4">
            <MdNetworkWifi3Bar className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Haute disponibilité</h3>
            <p className="text-gray-300">
              Notre infrastructure garantit une disponibilité de 99,99%, assurant ainsi que vos services restent accessibles en permanence.
            </p>
          </div>

          <div className="grid gap-4">
            <HiOutlineSupport className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Support client 24/7</h3>
            <p className="text-gray-300">
              Notre équipe de support est disponible 24 heures sur 24, 7 jours sur 7, pour répondre à toutes vos questions et résoudre vos problèmes rapidement.
            </p>
          </div>

          <div className="grid gap-4">
            <MdBackup className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Sauvegarde automatique des données</h3>
            <p className="text-gray-300">
              Nous effectuons des sauvegardes régulières et automatiques de vos données pour éviter toute perte et assurer une récupération rapide en cas de besoin.
            </p>
          </div>

          <div className="grid gap-4">
            <HiServerStack className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Scalabilité</h3>
            <p className="text-gray-300">
              Notre infrastructure flexible permet d’ajuster facilement vos ressources selon vos besoins en croissance.
            </p>
          </div>

          <div className="grid gap-4">
            <FaShieldAlt className="h-10 w-10 text-gray-300" />
            <h3 className="text-xl font-bold text-white">Sécurité renforcée</h3>
            <p className="text-gray-300">
              Nous utilisons les dernières technologies en matière de sécurité pour protéger vos données contre toute tentative d'intrusion ou de vol.
            </p>
          </div>
        </div>

        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div data-aos="fade-right" data-aos-duration="1200">
              <h2 className="text-4xl font-bold text-white text-center">Nos outils dédiés</h2>
              <p className="pt-8 text-white opacity-80 text-center">Nous proposons des outils spécialisés. Grâce à ces outils, nous garantissons un contrôle total de vos données et contribuons au bon développement de votre entreprise en offrant des alternatives aux outils existants.</p>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000">
              <div className="relative mx-auto border-slate-600 dark:border-slate-600 bg-slate-600 border-[16px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
                  <div className="rounded-xl overflow-hidden h-[140px] md:h-[262px]">
                      <img src={umamiPng} className="h-[140px] md:h-[262px] w-full rounded-xl" alt="" />
                  </div>
              </div>
              <div className="relative mx-auto bg-slate-700 dark:bg-slate-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px]"></div>
              <div className="relative mx-auto bg-slate-500 rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px]"></div>
            </div>
          </div>
        </div>
          

      </div>
    </>
  )
}